/* Attacking.css */
.content-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.chart-and-heatmap-container, .additional-charts-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-bottom: 10px;
}

.chartContainer, .heatmapContainer, .additional-chart-container {
    padding: 10px;
    background: white;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.fixed-table-container {
    height: 50vh; /* Half the viewport height */
    overflow: auto; /* Adds scrollbar if content overflows */
    background: white;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px;
    width: 100%;
    margin-bottom: 10px;
}

.buttonGroup {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
}

.fixed-players-thead th {
    background-color: #007bff; /* Bootstrap primary blue */
    color: white;
    font-weight: bold;
    padding: 12px 15px;
    border-bottom: 2px solid #006fe6; /* A slightly darker blue than the background */
    text-align: center;
    cursor: pointer; /* Indicates that the header is clickable */
}

.fixed-players-thead th:hover {
    background-color: #0056b3; /* A darker shade of blue for hover effect */
}

.loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.spinner-border {
    width: 3rem;
    height: 3rem;
}

.loading-text {
    margin-top: 1rem;
    font-size: 1.5rem;
}

@media (max-width: 768px) {
    .chart-and-heatmap-container,
    .additional-charts-container {
        grid-template-columns: 1fr; /* Stacks the charts on mobile devices */
    }

    .fixed-table-container {
        height: auto;
    }
}
