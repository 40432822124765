/* Players.css */
.players-container {
  padding: 20px;
}

.players-header {
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

.table-scroll-container {
  max-height: 500px; /* Adjust the height as needed */
  overflow-y: auto; /* Enables vertical scrolling */
  overflow-x: auto; /* Enables horizontal scrolling */
  margin-bottom: 20px;
  border: 1px solid #ddd; /* Optional border for the container */
}

.players-table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
}

.players-thead tr {
  background-color: #007bff;
  color: white;
}

.players-table th, .players-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.player-row.even {
  background-color: #f2f2f2;
}

.player-row.odd {
  background-color: #fff;
}

.player-row:hover {
  background-color: #ddd;
}

@media (max-width: 768px) {
  .players-header {
      font-size: 20px;
  }
  
  .players-table th, .players-table td {
      padding: 8px;
      font-size: 14px;
  }
}

.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.spinner-border {
  width: 3rem;
  height: 3rem;
}

.loading-text {
  margin-top: 1rem;
  font-size: 1.5rem;
}
