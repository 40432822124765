/* Defending.css */
.defensiveContainer {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
  
  .graphGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin-bottom: 10px;
  }
  
  .fullWidthGraphContainer {
    width: 100%;
    padding: 10px;
    background: white;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .instructionText {
    text-align: center;
    color: #888;
    margin-top: 20px;
  }
  
  @media (max-width: 768px) {
    .graphGrid {
      grid-template-columns: 1fr; /* Stacks the charts on mobile devices */
    }
  }
  