/* Sidebar.css */
.sidebar {
  width: 200px; /* Reduced width to reduce the gap */
  background-color: #282c34;
  color: white;
  padding: 10px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  transition: width 0.3s ease, opacity 0.3s ease;
}

.sidebar.closed {
  width: 0;
  opacity: 0;
  overflow: hidden;
}

.sidebar-header {
  margin-bottom: 30px;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.sidebar-header h3 {
  font-size: 1.5em;
  font-weight: 400;
  transition: opacity 0.3s ease;
}

.sidebar.closed .sidebar-header h3 {
  opacity: 0;
}

.year-selector, .team-selector {
  width: 100%;
  margin-bottom: 10px;
}

.year-dropdown, .team-dropdown {
  width: 100%;
  padding: 10px;
  margin: 1px 0;
  background-color: #282c34;
  color: white;
  border: 1px solid #444;
  border-radius: 5px;
  box-sizing: border-box;
}

.year-dropdown option, .team-dropdown option {
  background-color: #282c34;
  color: white;
}

button {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
