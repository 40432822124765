/* App.css */
.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  padding: 2px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed; /* Fix the header at the top */
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.toggle-button {
  background: none;
  border: none;
  color: white;
  font-size: 1.2em;
  cursor: pointer;
  flex: 0 0 10%; /* Take up 10% of the width */
}

.App-header h1 {
  font-size: 1.25em;
  margin: 0;
  flex: 1; /* Take up the remaining width */
  text-align: center;
}

.main-container {
  display: flex;
  height: 100vh;
  margin-top: 60px; /* Adjust based on the height of your header */
}

.App-sidebar {
  position: fixed; /* Fix the sidebar */
  top: 60px; /* Adjust based on the height of your header */
  left: 0;
  width: 220px; /* Adjust width as needed */
  height: calc(100vh - 60px); /* Full height minus the header */
  background: #fff; /* Sidebar background color */
  z-index: 999; /* Ensure it's above other content */
  overflow-y: auto; /* Enable vertical scrolling if needed */
  transition: width 0.3s ease;
}

.App-sidebar.closed {
  width: 0;
  overflow: hidden;
}

.content-area {
  flex: 1;
  padding: 20px;
  margin-left: 220px; /* Adjust this value to control the distance between the sidebar and content */
  transition: margin-left 0.3s ease;
}

.content-area.with-sidebar {
  margin-left: 220px; /* Adjust this value to control the distance between the sidebar and content */
}

.content-area.without-sidebar {
  margin-left: 0;
}

@media (max-width: 768px) {
  .App-header h1 {
    font-size: 1.2em;
  }

  .content-area.with-sidebar {
    margin-left: 0;
  }

  .sidebar {
    position: absolute;
    width: 250px;
    height: 100vh;
    z-index: 1000;
  }

  .App-sidebar.closed {
    width: 0;
    opacity: 0;
  }

  .App-sidebar.open {
    width: 250px;
    opacity: 1;
  }
}


