
.auth-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

  .background-container {
    width: 100%;
    height: 100%;
    background-size: cover;
    margin: 0;
    padding: 0;
  }
  
  .auth-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 320px;
    background: #ffffff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .auth-form h2 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .auth-form input {
    font-size: 1rem;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .auth-form button {
    padding: 0.6rem 1rem;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 1rem;
  }
  
  .auth-form button:hover {
    background: #0056b3;
  }
  
  .switch-auth {
    margin-top: 1rem;
    font-size: 0.9rem;
    text-align: center;
  }
  
  .switch-auth button {
    background: none;
    color: #007bff;
    padding: 0.3rem;
    border: none;
    text-decoration: underline;
    cursor: pointer;
  }
  
  .switch-auth button:hover {
    color: #0056b3;
  }

  .password-input-container {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 1rem;
  }
  
  .password-input-container input {
    flex: 1;
  }
  
  .view-password-button {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    margin-left: 0.5rem;
    padding: 0.3rem 0.6rem;
    font-size: 0.8rem;
  }

  .auth-message {
    margin-bottom: 1rem;
    color: #d32f2f; /* color for error messages */
    text-align: center;
  }
  
  